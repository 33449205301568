import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import HeroV8 from "../components/HeroV8.js";
import FeaturedContent from "../components/FeaturedContent.js";
import PageAdvert from "../components/PageAdvert.js";
import TestimonialSliderDark from "../components/TestimonialSliderDark.js";
import Seo from "../components/Seo";

import {
  SponsorAdverts,
  partnershipFeaturedContent,
  partnershipsHeroDetails,
  feedback,
} from "../constants/index.js";

const Partners = () => {

  const data = useStaticQuery(graphql`
    query {
      featuredContentImages: allFile(
        filter: {
          name: {
            in: ["01-partnerships", "02-partnerships", "03-partnerships"]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              # aspectRatio: 1.0
            )
          }
        }
      }
      advertImages: allFile(
        filter: {
          name: {
            in: [
              "03-burnham-banner-sponsorship"
              "slough-in-bloom-04"
              "red-cobra-chilli-sauce-v2"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.34
            )
          }
        }
      }
      heroImage: allFile(filter: { name: { in: "become-a-partner-v5" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.0
            )
          }
        }
      }
    }
  `);

  //console.log("Data - Sponsorship: ", data);

  const structuredData = (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Intact Electrical Ltd - Partners",
        "url":  "https://intact-electrical.co.uk/partners",
        "logo": "https://intact-electrical.co.uk/static/0e28fe2974beb0df4a3655e8f2b63a68/ad11a/logo-icon.avif"
      })}
    </script>
  )

  // advert details
  const burnhamFootballClub = SponsorAdverts.find((ad) => {
    return ad.id === "burnham-junior-fc";
  });

  const sloughInBloom = SponsorAdverts.find((ad) => {
    return ad.id === "slough-in-bloom";
  });

  const hsPepper = SponsorAdverts.find((ad) => {
    return ad.id === "hs-pepper-co";
  });

  // advert images
  const burnhamFootballClubImage = data.advertImages.nodes.find((image) => {
    return image.name === "03-burnham-banner-sponsorship";
  });

  const sloughInBloomImage = data.advertImages.nodes.find((image) => {
    return image.name === "slough-in-bloom-04";
  });

  const hsPepperImage = data.advertImages.nodes.find((image) => {
    return image.name === "red-cobra-chilli-sauce-v2";
  });

  const featuredContentImages = data.featuredContentImages.nodes;

  const heroBackgroundImage =
    data.heroImage.nodes[0].childImageSharp.gatsbyImageData;

  const {
    buttonLeft,
    buttonLeftLink,
    buttonRight,
    buttonRightLink,
    excerpt,
    titleTop,
    titleBottom,
  } = partnershipsHeroDetails;

  return (
    <>
      <Seo       
        title="Partners"
        description="Intact Electrical, community first, Slough In Bloom 2023, Burnham FC Junior H&S Pepper, What people say about us"
        post={false}
        metaRobots="nofollow, index"
        structuredData={structuredData}
      />
      <HeroV8
        heroImage={heroBackgroundImage}
        heroTitleTop={titleTop}
        heroTitleBottom={titleBottom}
        excerpt={excerpt}
        btnLeft={buttonLeft}
        btnLeftLink={buttonLeftLink}
        btnRight={buttonRight}
        btnRightLink={buttonRightLink}
      />
      <FeaturedContent
        featuredContent={partnershipFeaturedContent}
        featuredContentImages={featuredContentImages}
      />
      <PageAdvert ad={burnhamFootballClub} image={burnhamFootballClubImage} />
      <PageAdvert ad={sloughInBloom} image={sloughInBloomImage} />
      <PageAdvert ad={hsPepper} image={hsPepperImage} />

      <TestimonialSliderDark feedback={feedback} />
    </>
  );
};

export default Partners;
